export default function validate(values, formMessages) {
  let error = ''

  if (!values.name || !values.email || !values.message) {
    error = formMessages[0]
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    error = formMessages[1]
  }

  return error
}
