import React from 'react'
import PropTypes from 'prop-types'
import useForm from '../hooks/useForm'
import styled, { css } from 'styled-components'
import Button from '../components/button'
import validate from '../util/validation'
import { colors } from '../util/colors'

const textBoxStyles = css`
  background: ${colors.white};
  border: 2px solid ${colors.lightGray};
  border-radius: 1rem;
  color: ${colors.brandPrimary};
  font-family: inherit;
  font-size: 1rem;
  margin: 0;
  outline: none;
  padding: 1rem;
  width: 100%;

  &::placeholder {
    color: ${colors.mediumGray};
    font-weight: 600;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px ${colors.white} inset;
    -webkit-text-fill-color: ${colors.brandPrimary};
  }
  &:-webkit-autofill::first-line {
    font-family: 'Quicksand', serif;
    font-size: 1rem;
  }
`
const Form = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`
const Field = styled.div`
  min-width: 320px;
  padding: 0.5rem;
  width: 30vw;

  @media (max-width: 650px) {
    width: 80vw;
  }
`
const Label = styled.label`
  display: none;
`
const Input = styled.input`
  ${textBoxStyles}
`
const TextArea = styled.textarea`
  ${textBoxStyles}
`
const FormMessage = styled.p`
  color: ${colors.brandPrimary};
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
`

const FormField = ({ label, type, value, ...rest }) => (
  <Field>
    <Label>{label}</Label>
    {type === 'textarea' ? (
      <TextArea
        required
        placeholder={label}
        rows="6"
        value={value || ''}
        {...rest}
      />
    ) : (
      <Input
        required
        placeholder={label}
        type="text"
        value={value || ''}
        {...rest}
      />
    )}
  </Field>
)

const ContactForm = ({
  buttonStates,
  formFields,
  formMessages,
}) => {
  const {
    formMessage,
    handleChange,
    handleSubmit,
    isSubmitting,
    values,
  } = useForm(validate, formMessages)

  return (
    <Form
      noValidate
      onSubmit={handleSubmit}
    >
      {formFields.map(({ label, name, type }) => (
        <FormField
          key={`formField-${name}`}
          label={label}
          name={name}
          onChange={handleChange}
          type={type}
          value={values[name]}
        />
      ))}
      <Button
        type="submit"
        color={colors.brandPrimary}
        style={{ marginTop: '1rem' }}
      >
        {isSubmitting ? buttonStates[1] : buttonStates[0]}
      </Button>
      <FormMessage>
        {formMessage}
      </FormMessage>
    </Form>
  )
}

ContactForm.propTypes = {
  buttonStates: PropTypes.arrayOf(PropTypes.string).isRequired,
  formFields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  formMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ContactForm
