import axios from 'axios'
import { useState } from 'react'

export const GOOGLE_FORM_NAME_ID = 'entry.1281104913'
export const GOOGLE_FORM_EMAIL_ID = 'entry.395993581'
export const GOOGLE_FORM_MESSAGE_ID = 'entry.1518173618'
export const GOOGLE_FORM_ACTION = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSdNChANhKCIe9lqjeRYhiDcXTCcAXaEAAuWHLjpVuZVgE3LWg/formResponse'
const intialValues = {}
const intialFormMessage = ''

const useForm = (validate, formMessages) => {
  const [values, setValues] = useState(intialValues)
  const [formMessage, setFormMessage] = useState(intialFormMessage)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const sendMessage = () => {
    const formData = new FormData()
    formData.append(GOOGLE_FORM_NAME_ID, values.name)
    formData.append(GOOGLE_FORM_EMAIL_ID, values.email)
    formData.append(GOOGLE_FORM_MESSAGE_ID, values.message)

    // this is a hack to submit form responses to a google sheet without needing to embed a branded
    // iframe form and limiting custom styles. the downside to this approach is actual form failures
    // will not be distinguishable from successes, but this should be rare given google doc uptime
    axios.post(GOOGLE_FORM_ACTION, formData)
      .then(() => {
        // never succeeds
      }).catch(() => {
        // actual success
        setIsSubmitting(false)
        setValues(intialValues)
        setFormMessage(formMessages[2])
      })
  }

  const handleSubmit = (ev) => {
    if (ev) ev.preventDefault()
    const newFormMessage = validate(values, formMessages)
    const isValid = newFormMessage.length === 0
    if (isValid) {
      setIsSubmitting(true)
      sendMessage()
    }
    setFormMessage(newFormMessage)
  }

  const handleChange = (ev) => {
    ev.persist()
    setValues(values => ({
      ...values,
      [ev.target.name]: ev.target.value,
    }))
  }

  return {
    formMessage,
    handleChange,
    handleSubmit,
    isSubmitting,
    values,
  }
}

export default useForm
