import React, { useRef, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
// components
import Img from 'gatsby-image'
import Button from '../components/button'
import ContactForm from '../components/contactForm'
import Layout from '../components/layout'
import Section from '../components/section'
import SEO from '../components/seo'
// graphics
import ExecutiveCoaching from '../components/graphics/executiveCoaching'
import MentorCoaching from '../components/graphics/mentorCoaching'
import LeadershipDevelopment from '../components/graphics/leadershipDevelopment'
// utils
import { colors } from '../util/colors'
import { INTIIAL_HEADER_VIEWHEIGHT } from '../util/constants'
import { languages, translateOptions } from '../util/languages'

const HeaderText = styled.h1`
  color: ${colors.white};
  font-size: max(1.4rem, calc(1.3vw + 1.3vh + 0.6vmin));
  font-weight: 600;
  line-height: 1.6;
  margin: 0 0 2.2rem 0;
  position: relative;
  z-index: 2;
`
const Text = styled.p`
  color: ${colors.brandPrimary};
  font-size: max(1rem, calc(0.8vw + 0.2vh + 0.8vmin));
  font-weight: 600;
  line-height: 1.35;
  margin: 0 0 1rem;

  &:last-child {
    margin: 0;
  }
`
const TestimonialText = styled(Text)`
  text-align: right;
`
const ImageWrapper = styled.div`
  border-radius: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 650px) {
    flex: none;
    margin-bottom: 1rem;
    width: 100%;
  }
`
const ContentWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0 5vw;

  @media (max-width: 650px) {
    align-items: center;
    padding: 3vw 0 0 0;
    width: 100%;

    h1 {
      width: 100%;
      text-align: center;
    }
    p {
      font-size: max(1rem, 3vw);
    }
  }
`
const ServiceWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 300px;
  padding: max(2rem, 2vw);

  &:last-child {
    margin-right: 0;
  }
`
const SectionTitle = styled.h2`
  color: ${colors.brandPrimary};
  flex: 1;
  font-size: max(1.5rem, calc(1vw + 2vh + 0.5vmin));
  margin: 0 0 max(2rem, 3vw) 0;
`
const ServiceHeader = styled.h3`
  color: ${colors.brandPrimary};
  font-size: max(1.3rem, calc(1vw + 1.5vh + 0.5vmin));
  margin: 2rem 0;
  font-weight: 700;
  text-align: center;
`
const ServiceTextWrapper = styled.div`
  background-color: ${colors.white};
  border-radius: 1rem;
  padding: max(2rem, 3vw);
  position: relative;

  &::before {
    background-color: ${colors.brandSecondary};
    border-radius: 1rem;
    content: '';
    height: 100%;
    left: 1rem;
    opacity: 0.6;
    position: absolute;
    top: 1rem;
    width: 100%;
    z-index: -1;
  }
`
const FlexWrapper = styled.div`
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  z-index: 2;
`
const Link = styled.a`
  color: ${colors.brandSecondary};
`;
const ContactFormWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;

  h2 {
    margin-right: max(2rem, 3vw);
    max-width: 45%;
  }

  @media (max-width: 650px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;

    h2 {
      margin-bottom: max(2rem, 3vw);
      margin-right: 0;
      max-width: 320px;
      text-align: center;
    }
  }
`;
const TestimonialTextWrapper = styled(ServiceTextWrapper)`
  flex: 1;
  margin: 0 2rem 2rem 0;
  min-width: max(300px, 25vw);

  @media (max-width: 650px) {
    margin: 0 1rem 2rem 0;
  }
`

const scrollToRef = (ref) => {
  const { clientHeight: headerHeight } = document.getElementById('header')
  const headerOffset = window.scrollY > window.innerHeight / 2 ? headerHeight : headerHeight * (2 / 3)

  window.scrollTo({
    behavior: 'smooth',
    top: ref.current.offsetTop - headerOffset,
  })
}

const IndexPage = () => {
  const [language, setLanguage] = useState(translateOptions[0])
  const lang = languages[language.value]

  const data = useStaticQuery(graphql`
    query {
      headshotImage: file(relativePath: { eq: "headshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      groupshotImage: file(relativePath: { eq: "groupshot.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const homeRef = useRef(null)
  const aboutRef = useRef(null)
  const servicesRef = useRef(null)
  const contactRef = useRef(null)

  return (
    <Layout
      handleNavigate={scrollToRef}
      handleTranslate={setLanguage}
      language={language}
      navigationLinks={lang.navigationLinks}
      refs={{ homeRef, aboutRef, servicesRef, contactRef }}
    >
      <SEO title="Home" />
      <Section
        color={colors.brandSecondary}
        ref={homeRef}
        style={{
          backgroundImage: `linear-gradient(to bottom right, ${colors.brandSecondary}, ${colors.brandPrimary})`,
          paddingTop: `${INTIIAL_HEADER_VIEWHEIGHT}vh`,
          paddingBottom: 0,
        }}
      >
        <ImageWrapper style={{ borderRadius: 0 }}>
          <Img
            alt="Dr. Rosa professional headshot"
            fluid={data.headshotImage.childImageSharp.fluid}
          />
        </ImageWrapper>
        <ContentWrapper>
          <HeaderText>{lang.splashText}</HeaderText>
          <Button
            outline
            color={colors.white}
            onClick={() => scrollToRef(servicesRef)}
            style={{ marginBottom: 'max(2rem, 3vw)' }}
          >
            {lang.splashButton}
          </Button>
        </ContentWrapper>
      </Section>
      <Section
        color={colors.lightGray}
        ref={aboutRef}
        style={{ flexDirection: 'column' }}
      >
        <SectionTitle>{lang.aboutTitle}</SectionTitle>
        <ContentWrapper>
          <Text>
            {lang.aboutText[0]}
            <Link href="https://cclinnovation.org/wp-content/uploads/2020/02/building-the-case-for-executive-coaching.pdf" target="_blank" rel="noopener noreferrer">{lang.aboutLinkText[0]}</Link>
            {lang.aboutText[1]}
          </Text>
          <Text>
            {lang.aboutText[2]}
            <Link href="https://www.ccl.org/articles/client-successes/inspiring-latinas-to-become-leaders/" target="_blank" rel="noopener noreferrer">{lang.aboutLinkText[1]}</Link>
            {lang.aboutText[3]}
          </Text>
        </ContentWrapper>
      </Section>
      <Section
        color={colors.lightGray}
        ref={servicesRef}
        style={{ flexDirection: 'column' }}
      >
        <SectionTitle style={{ margin: 0 }}>{lang.servicesTitle}</SectionTitle>
        <FlexWrapper>
          <ServiceWrapper>
            <ExecutiveCoaching />
            <ServiceHeader>{lang.serviceHeader1}</ServiceHeader>
            <ServiceTextWrapper>
              {lang.serviceText1.map((text, i) => (
                <Text key={`serviceText1-${i}`}>
                  {text}
                </Text>
              ))}
            </ServiceTextWrapper>
          </ServiceWrapper>
          <ServiceWrapper>
            <LeadershipDevelopment />
            <ServiceHeader>{lang.serviceHeader2}</ServiceHeader>
            <ServiceTextWrapper>
              {lang.serviceText2.map((text, i) => (
                <Text key={`serviceText2-${i}`}>
                  {text}
                </Text>
              ))}
            </ServiceTextWrapper>
          </ServiceWrapper>
          <ServiceWrapper>
            <MentorCoaching />
            <ServiceHeader>{lang.serviceHeader3}</ServiceHeader>
            <ServiceTextWrapper>
              {lang.serviceText3.map((text, i) => (
                <Text key={`serviceText3-${i}`}>
                  {text}
                </Text>
              ))}
            </ServiceTextWrapper>
          </ServiceWrapper>
        </FlexWrapper>
        <Button
          onClick={() => scrollToRef(contactRef)}
          style={{ marginTop: '1rem' }}
        >
          {lang.servicesButton}
        </Button>
      </Section>
      <Section
        color={colors.lightGray}
        style={{ flexDirection: 'column' }}
      >
        <SectionTitle>{lang.diversityTitle}</SectionTitle>
        <FlexWrapper style={{ alignItems: 'flex-start' }}>
          <ImageWrapper>
            <Img
              alt={lang.diversityPhotoAltText}
              fluid={data.groupshotImage.childImageSharp.fluid}
            />
          </ImageWrapper>
          <ContentWrapper>
            {lang.diversityText.map((text, i) => (
              <Text key={`diversityText-${i}`}>
                {text}
              </Text>
            ))}
          </ContentWrapper>
        </FlexWrapper>
      </Section>
      <Section
        color={colors.lightGray}
        style={{ flexDirection: 'column', flexWrap: 'wrap' }}
      >
        <SectionTitle>{lang.testimonialsTitle}</SectionTitle>
        <FlexWrapper>
          {lang.testimonialsText.map((testimonial) =>(
            <TestimonialTextWrapper key={`testimonial-${testimonial.name}`}>
              <Text>
                "{testimonial.text}"
              </Text>
              <TestimonialText>
                - {testimonial.name}
              </TestimonialText>
            </TestimonialTextWrapper>
          ))}
        </FlexWrapper>
      </Section>
      <Section
        color={colors.lightGray}
        ref={contactRef}
      >
        <ContactFormWrapper>
          <SectionTitle>
            {lang.contactText}
          </SectionTitle>
          <ContactForm
            buttonStates={lang.contactFormButton}
            formFields={lang.contactFormFields}
            formMessages={lang.formMessages}
          />
        </ContactFormWrapper>
      </Section>
    </Layout>
  )
}

export default IndexPage
