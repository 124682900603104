import React from 'react'

const LeadershipDevelopment = () => (
  <svg
    preserveAspectRatio="xMinYMin"
    style={{ shapeRendering: 'geometricPrecision', padding: '1rem' }}
    viewBox="0 0 946 636.5"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M41.6 601.8c12.5 23 38.8 33 38.8 33s6.3-27.5-6.2-50.5-38.8-33-38.8-33-6.2 27.5 6.2 50.5z" fill="#2f2e41"/>
    <path d="M50.2 594.3c22.4 13.5 31 40.3 31 40.3s-27.7 4.9-50.1-8.6S0 585.7 0 585.7s27.7-5 50.2 8.6z" fill="#3b85c2"/>
    <path fill="#3f3d56" d="M27 634.5h909v2H27z"/>
    <path fill="#3b85c2" d="M458.2 174.7h75.5v14.8h-75.5zM397 235.9h75.5v14.8H397z"/>
    <path fill="#3f3d56" d="M537.1 346.8h38v14.8h-38zM667.8 346.8h38v14.8h-38zM736.4 346.8h38v14.8h-38zM701.9 459.3h38v14.8h-38zM839 459.3h38v14.8h-38zM804.9 346.8h38v14.8h-38zM873.5 346.8h38v14.8h-38zM946 266.9H495.2v-63.6h1v62.6H946v1z"/>
    <path fill="#3f3d56" d="M910.2 380.6H618.6v-114h1v113h290.6v1zM555.6 266.7h1v63.6h-1z"/>
    <path fill="#3f3d56" d="M686.3 266.7h1v63.6h-1zM754.9 266.7h1v63.6h-1zM823.4 266.7h1v63.6h-1zM892 266.7h1v63.6h-1zM720.4 380.1h1v63.6h-1zM857.5 380.1h1v63.6h-1z"/>
    <path fill="#3b85c2" d="M458.2 299.6h75.5v14.8h-75.5z"/>
    <path fill="#3f3d56" d="M537.1 127.5h38v14.8h-38zM667.8 127.5h38v14.8h-38zM736.4 127.5h38v14.8h-38zM701.9 15h38v14.8h-38zM839 15h38v14.8h-38zM804.9 127.5h38v14.8h-38zM873.5 127.5h38v14.8h-38zM946 222.2H495.2v63.6h1v-62.6H946v-1z"/>
    <path fill="#3f3d56" d="M910.2 108.5H618.6v114h1v-113h290.6v-1zM555.6 158.9h1v63.6h-1z"/>
    <path fill="#3f3d56" d="M686.3 158.9h1v63.6h-1zM754.9 158.9h1v63.6h-1zM823.4 158.9h1v63.6h-1zM892 158.9h1v63.6h-1zM720.4 45.4h1V109h-1zM857.5 45.4h1V109h-1z"/>
    <path d="M299.1 21.1c-11.9-20-35.4-21-35.4-21s-23-3-37.7 27.7c-13.7 28.5-32.6 56-3 62.8l5.3-16.7 3.3 17.9a115.8 115.8 0 0012.7.2c31.7-1 61.9.3 61-11-1.4-15.2 5.3-40.5-6.2-59.9z" fill="#2f2e41"/>
    <path d="M255.2 89.6c0 2.3 0 4.7-1 6.7-2.3 3.9-7.7 4.4-11 7.5-3.5 3.2-3.8 8.5-4 13.2-.2 5-.3 10 1.5 14.6a35.5 35.5 0 004.1 7.2l6 8.6a37.2 37.2 0 003.7 4.5c3.7 3.7 8.7 5.8 13.6 7.2a17 17 0 0010 .7 19 19 0 005.7-3.5 30.9 30.9 0 006.5-6.4c2.7-3.9 3.5-8.7 4-13.5a291 291 0 002-41.9 10.3 10.3 0 00-.8-4.4c-1.7-3.3-6.1-4-8.5-6.7-2-2.4-2-5.8-2-9v-7.8a3.3 3.3 0 00-.4-2 3.3 3.3 0 00-2.3-1 76.6 76.6 0 00-14.9-1c-4 0-11.8-.2-15.2 2-3.2 2 .8 7.7 1.5 10.9a70.7 70.7 0 011.5 14.1z" fill="#ffb8b8"/>
    <path d="M189.5 619.7c-1 3.5-1.5 7.6.6 10.6a10.6 10.6 0 004.5 3.2 32 32 0 0014.3 2.1 6.7 6.7 0 005-1.9 62.3 62.3 0 0021.6-36.8 3.5 3.5 0 00-.1-2.2 3.6 3.6 0 00-1.5-1.3 33.7 33.7 0 00-8-3.7c-15.5-4-32.6 17-36.4 30zM352 570.7a22.8 22.8 0 007.5 5.8 10.6 10.6 0 009.2-.2c4.3-2.4 5.5-8 5.2-13-.7-9-5-17.1-10-24.5-1.5-2.4-5-9.3-8-9.4-1.5 0-7 5.2-8.4 6.2-5.4 3.7-9.1 6.3-8 13.3a47 47 0 0012.5 21.8z" fill="#2f2e41"/>
    <circle cx="264.5" cy="44" r="32" fill="#ffb8b8"/>
    <path d="M251.3 123.3a46.8 46.8 0 01-5-9.7 15.7 15.7 0 010-10.7c-3.5-2-8.2-.4-10.7 2.7s-3.3 7.3-3.5 11.3 0 8-.9 12c-2 8.6-9 15.7-9.6 24.5-.5 8.7 5.5 16.3 8.4 24.4a40.8 40.8 0 012.2 16c-.1 1.7-.2 3.7 1 5a5.7 5.7 0 002.2 1.1 144 144 0 0044.4 5.8l24.8.6a7.9 7.9 0 004.3-.7c2.2-1.4 2.5-4.4 2.6-7 .3-22.4-.6-44.9-1.6-67.3-.3-8.1-.7-16.3-2.4-24.2-.5-2-1.2-4.3-3-5.2-1.6-.7-3.5-.3-5.1.3a40.6 40.6 0 00-19 14.7c-5.1 7.2-7.6 15.9-12.6 23.2-3.6 5.3-5.7.6-8.1-3.2l-8.4-13.6z" fill="#3b85c2"/>
    <path d="M243.1 94.2c-1.9 1-4 1.4-5.8 2.5a17.5 17.5 0 00-5 5.6l-7.7 11.4a35.4 35.4 0 00-4.3 8c-1.2 3.4-1.3 7.1-1.4 10.8q-.5 15.2-1.9 30.4a109.4 109.4 0 01-2.1 14.6c-1 3.9-2.2 7.7-3.5 11.4l-14 41.7a15.7 15.7 0 00-1.2 6.6c.2 2.2 1.7 4.5 3.9 4.9a7.7 7.7 0 004.2-.9 45.5 45.5 0 0014.8-9 54.7 54.7 0 0012-25.6 58 58 0 002.6-15c0-11.5-6.8-22.1-7-33.6a59 59 0 01.9-9.4c1.2-8.4 2.5-16.8 5.4-24.8s7.6-15.4 14.5-20.3c3.3-2.4 12.2-7 8.7-12.5-3.4-5.3-9.7 1.6-13 3.2z" fill="#575a88"/>
    <path d="M304.8 197.4q-34.4-2.7-68.9-4.5c-2 0-4.1 0-5.4 1.3a6.7 6.7 0 00-1.3 2.4l-4.9 13.2c-2.2 5.9-3.1 11.4-7.5 16s-9.5 8-13.8 12.6a46.9 46.9 0 00-12 24.7c-1.2 7.5-.6 15.4-2.5 22.8-2.4 9.2 1.4 18.7 1.4 28.1-.1 14.1-2 28.1-4 42-5.6 40.5-11.2 81-17.2 121.3-5 33.1-10 68.9 5.6 98.6 3.8 7 8.8 13.8 16 17.5a48.2 48.2 0 0012.4 3.9 285.2 285.2 0 0033.3 5c4.6.3 9.9.3 12.8-3.2 2-2.4 2.2-5.6 2.4-8.7l1.3-20c1.5-25.8 1-52.6-4.8-77.9-2-8.5-4.4-16.9-6-25.4a136.6 136.6 0 01-2.4-24.8 78.8 78.8 0 011.6-17.7c4.2-18.7 18.2-35.7 16.2-54.8 12.9 18.9 17.1 40.5 21.2 63a307 307 0 0011.6 48.6c6.1 17.1 15 34 13.8 52.1a68.2 68.2 0 01-1.9 11.2 19 19 0 0026 21.8l.4-.1c13.2-6 19.8-22 33.3-27.5 6.2-2.6 14-3 16.5-10.6a13.5 13.5 0 00.6-5.5c-.6-6.2-4.2-11.2-7-16.5-6-11.6-8.2-24.8-10.3-37.7l-6.4-38.4c-1.3-8.4-2.6-16.8-5.4-24.9-1.2-3.5-2.7-7-3.7-10.5-3.6-13.4.2-27.7-1.3-41.5-1.7-15.6-4.9-31-8.3-46.2a328 328 0 01-6-60.4 69 69 0 00-3.6-23.7 63 63 0 00-21.8-25.6zM293.9 19.2L270.6 7l-32.1 5-6.7 29.3 16.6-.6 4.6-10.8v10.6l7.6-.3 4.5-17.1 2.7 18.2 27.2-.5-1.1-21.6z" fill="#2f2e41"/>
    <path d="M281.6 108.4a13 13 0 016.8-3.4 3.5 3.5 0 002.5-1 3 3 0 00.4-1.7 86.8 86.8 0 00-1.4-16.9 17 17 0 016.8-.9h21.6c4.3 0 8.8.1 12.7 2a20.3 20.3 0 019.2 9.3 54.5 54.5 0 014.2 12.8c2.6 11-6.6 22.2-7.5 33.5-.2 3.3 0 6.5-.2 9.8-.3 3.4-1.3 6.8-1.4 10.2 0 4.3 1.2 8.5 2.6 12.5 4.4 12.2 10.6 23.8 15.7 35.8a29.9 29.9 0 012.5 8.6 11.2 11.2 0 01-2.3 8.5c-2.1 2.4-5.4 3.3-8.3 4.4a54.1 54.1 0 00-10.6 5.5 22.3 22.3 0 01-4.3 2.5 27.4 27.4 0 01-4.6 1c-4 .7-7.7 2.4-11.6 3.6-1.3.5-2.7.8-3.9.1a4.7 4.7 0 01-1.5-1.7c-7.4-12.8-10-27.8-11.7-42.5s-2.6-29.6-7.1-43.7c-1.4-4.3-3-8.5-3.8-12.9-1.2-6.4-.3-13-1.5-19.4-1-5.7-3.7-10.3-3.3-16z" fill="#575a88"/>
    <path d="M330.7 230.5c-6.4 3.1-11.4 8-15.7 13.6-1 1.2.3 3.5 3 3.4 2.5 0 4-3.2 6.6-3.3a110 110 0 004.2 20 50 50 0 0011-16.8l5.7-12c2-4.4 4.2-8.8 6-13.3a9.6 9.6 0 001-3.3c.3-7.8-7.4-7.8-10.7-3-3.6 5.3-4.7 11.7-11 14.7z" fill="#ffb8b8"/>
    <path d="M332 97.4c5 5 9.9 10.2 13.7 16.2s6.4 12.8 6.3 19.9a19 19 0 00.1 5.3 15.3 15.3 0 002.5 4.5 86 86 0 0111 23 20.1 20.1 0 001.8 4.5l2.5 3.4a12.7 12.7 0 01.3 13.9 4.7 4.7 0 00-.9 1.7c-.3 2 2.1 3.1 3 4.9 1 1.7 0 4-1.5 5.3a13.6 13.6 0 01-5.4 2.5c2.5 3.6-.5 8.6-1.6 13-.7 3-.7 6-1.1 9.1s-1.6 6.2-4 8.2a2.4 2.4 0 01-1 .6c-1.3.2-2.2-1.3-2.6-2.5-1.5-3.9-2.9-7.8-5.4-11.1s-6.3-5.9-10.4-5.9a5.6 5.6 0 01.4-5c.8-1.6 2-3 3-4.5a20.2 20.2 0 002.3-6.6 43.6 43.6 0 00-5.8-31.1 29.8 29.8 0 00-4.8-6c-2-1.9-4.4-3.4-6.2-5.5a21.3 21.3 0 01-5-12 63.5 63.5 0 01.4-13.2c.8-8.7 1.9-17.4 3.1-26a9.7 9.7 0 01.9-3.3c1-1.9 2.6-2.4 4.5-3.3z" fill="#575a88"/>
  </svg>
)

export default LeadershipDevelopment
