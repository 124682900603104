import React from 'react'

const MentorCoaching = () => (
  <svg
    preserveAspectRatio="xMinYMin"
    style={{ shapeRendering: 'geometricPrecision', padding: '1rem' }}
    viewBox="0 0 887.9 652"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M887.9 633.4c-2.9 25.9-152.3 23.7-152 0 2.8-25.9 152.2-23.7 152 0z" fill="#e6e6e6"/>
    <path fill="#ffb8b8" d="M785.3 567l-6.1 13.1-11.1-1-7-23.2 11-6 13.2 17.1zM823.4 597.2l.2 9.2-17.1 1v-11.1l16.9 1z"/>
    <path fill="#2f2e41" d="M817.6 430.8l-4 80.7 10 87.8-30.3 4-19.1-111-6.1-75.7 49.5 14.2z"/>
    <path d="M865 401.5s12.1 79.7-13.1 106-59.6 72.6-59.6 72.6L768.1 550l61.6-65.6-12.1-44.4-49.5-23.3 8-55.5 72.7-1z" fill="#2f2e41"/>
    <circle cx="805.4" cy="194.6" r="22.2" fill="#ffb8b8"/>
    <path d="M824.6 199.7s1 31.2 6 35.3-18 21.2-18 21.2L797.3 232v-22.3z" fill="#ffb8b8"/>
    <path fill="#d0cde1" d="M814.5 242l12.3-18.5 6.9 9.5 10.1 28.2-8 110-47.5 1-6-126.1 15-18.2 17.2 14.1z"/>
    <path d="M763 249.1l-8-1s-2 1-3 8-13.2 69.7-13.2 69.7l16.2 76.7 18.1-24.2-11-42.4 11-42.4zM866 248.1h6l15.2 81.7-14.1 63.6-14.2-23.2 4-25.2-2-22.2-7-13.1L866 248zM776.2 576.1s6-2 6 2-4 16.2-4 16.2 6 37.3-3 35.3-16.2-19.2-17.2-24.2l-6-31.3s-8.1-19.2-3-20.2 19.1-5 19.1-5-8 25.2 8 27.2zM805.4 607.4s16.2-10.1 19.2-2a84.8 84.8 0 014 18.1s4 13.2-12 11.1-14.2-2-15.2-8 4-19.2 4-19.2zM818.6 194.6l-4-1s-3.1-17.2-10.2-15.1-25.2 4-25.2-4 17.2-15.2 27.3-14.2 27.2 4 26.2 19.2-7.5 29.8-7.5 29.8l-.6-9z" fill="#2f2e41"/>
    <path fill="#d0cde1" d="M797.4 254.2l17.1-12.2-17.1-14-6 7 6 19.2zM829.7 253.1l-15.2-11 12.1-18.2 7.1 9-4 20.2z"/>
    <path d="M756 248.1l32.3-13.1 7.6-5.6 22.7 100.4 15.1-45.4-4.5-58 41.8 21.7-14 68.6-2.1 26.3 6 21.2s21.2 15.1 14.2 31.2-15.2 17.2-15.2 17.2-34.3-32.3-36.3-40.4-5-22.2-5-22.2-17.2 64.6-37.4 63.6-20.2-22.2-20.2-22.2l5-22.2 8.2-23.2-4-38.4z" fill="#2f2e41"/>
    <path fill="#e6e6e6" d="M.3.4h703.6V451H.3z"/>
    <path fill="#fff" d="M20.4 56.9h663.3v171.8H20.4z"/>
    <path fill="#e6e6e6" d="M185.4 81.7h140.3v8.1H185.4z"/>
    <path fill="#3b85c2" d="M185.4 111.1H402v8.1H185.4z"/>
    <path fill="#e6e6e6" d="M185.4 138.8h176.5v8.1H185.4zM185.4 166.3h103.1v8.1H185.4zM185.4 193.9h155.5v8.1H185.4zM121.5 78.9h17.2v17.2h-17.2z"/>
    <path fill="#3b85c2" d="M121.5 106.5h17.2v17.2h-17.2z"/>
    <path fill="#e6e6e6" d="M121.5 134.2h17.2v17.2h-17.2zM121.5 161.9h17.2v17.2h-17.2zM121.5 189.6h17.2v17.2h-17.2zM533.7 118H591v57.3h-57.3z"/>
    <path fill="#fff" d="M20.4 252.5h663.3v171.8H20.4z"/>
    <path fill="#e6e6e6" d="M151.1 267.8h17.2V285h-17.2z"/>
    <path fill="#3b85c2" d="M121.5 267.8h17.2V285h-17.2z"/>
    <path d="M412.7 305.4a65 65 0 00-105-9.7l-4.2-3.7a70.6 70.6 0 01114 10.5z" fill="#e6e6e6"/>
    <path d="M415.3 377.8l-4.6-3.1a65 65 0 002-69.3l4.8-2.9a70.6 70.6 0 01-2.2 75.3zM304.1 385.5a70.6 70.6 0 01-.6-93.5l4.2 3.7a65 65 0 00.6 86.1z" fill="#3b85c2"/>
    <path d="M356.7 409a70.7 70.7 0 01-52.6-23.5l4.2-3.7a65 65 0 00102.4-7.1l4.6 3a70.6 70.6 0 01-58.6 31.4z" fill="#3b85c2"/>
    <path fill="#e6e6e6" d="M533.7 305H591v57.3h-57.3zM119.1 342.2h57.3v57.3h-57.3z"/>
    <path fill="#3b85c2" d="M0 0h703.6v29.9H0z"/>
    <circle cx="22.2" cy="15.3" r="5.5" fill="#fff"/>
    <circle cx="43.2" cy="15.3" r="5.5" fill="#fff"/>
    <circle cx="64.3" cy="15.3" r="5.5" fill="#fff"/>
  </svg>
)

export default MentorCoaching
