import React from 'react'

const ExecutiveCoaching = () => (
  <svg
    preserveAspectRatio="xMinYMin"
    style={{ shapeRendering: 'geometricPrecision', padding: '1rem 0 1rem' }}
    viewBox="0 0 930 788"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
      <circle cx="748.2" cy="49.5" r="14.8" fill="#f1f1f1"/>
      <circle cx="661.2" cy="620.5" r="14.8" fill="#f1f1f1"/>
      <circle cx="294.2" cy="191.5" r="14.8" fill="#f1f1f1"/>
      <ellipse cx="157" cy="765" rx="157" ry="23" fill="#f1f1f1"/>
      <path d="M240 599l39.4-12.4a144.5 144.5 0 00-14.8-47L225.2 552a88.6 88.6 0 0114.7 47z" fill="#3b85c2"/>
      <circle cx="145.2" cy="266.4" r="36.8" fill="#2f2e41"/>
      <path fill="#9f616a" d="M105.5 727.5l-7 30h17l7-34-17 4zM187.5 727.5v28l17 2v-30h-17zM100.5 409.5s2 43 4 46 20 46 20 46l9-28s-14-23-12-36 .7-29 .7-29z"/>
      <circle cx="157.5" cy="277.5" r="28" fill="#9f616a"/>
      <path d="M134.5 280.5s1 40-6 46 40 1 40 1-10-28-2-34-32-13-32-13z" fill="#9f616a"/>
      <path d="M171.5 325.5s-6-11-14-10-34 3-35 5-4 9-6 11-17 5-18 17 36 85 36 85-5 17-4 21-5 16-4 21-15 35-7 74v63s-27 109-19 117 35 3 40 0 26-154 26-154l10-51 1 112s-2 94 5 97 34 4 36-2c1.6-4.7 9.2-129.2 12.5-183.8a203.9 203.9 0 00-4.4-56.1c-4.2-19-10.2-41.1-17.1-50.1-13-17-21-71-21-71l17-31s-10-10-16-10-18-4-18-4z" fill="#2f2e41"/>
      <path d="M104.5 341.5l-5.3 3.6s-3.7 64.4-.7 68.4 23.9 2 25.4-1.5-19.4-70.5-19.4-70.5z" fill="#2f2e41"/>
      <path d="M186.5 401.5s1 42 7 54 28 62 28 62-1.3 55 9.9 41.5 4.1-45.5 4.1-45.5l-19-74s-10-33-9-43-21 5-21 5z" fill="#9f616a"/>
      <path d="M204.5 739.3s1-.8 4 2.2 11 7 10 18-7 13-7 13v-3h-3v5s-5 16-28 6c0 0-1-8 0-13s1-29 4-28 11 2 13 6 8 2.6 7-6.2zM101.5 740.3s-1-.8-4 2.2-11 7-10 18 7 13 7 13v-3h3v5s5 16 28 6c0 0 1-8 0-13s-1-29-4-28-11 2-13 6-8 2.6-7-6.2zM191.5 336.5l14 3s0 22 4 31 11 30 8 31-31 15-33 7-7-72 7-72z" fill="#2f2e41"/>
      <circle cx="105.3" cy="217.9" r="36.8" fill="#2f2e41"/>
      <path d="M73.6 248.4a36.8 36.8 0 0056.8-19.4 36.8 36.8 0 11-71.8-15 36.8 36.8 0 0015 34.4z" fill="#2f2e41"/>
      <circle cx="135.4" cy="272.5" r="27" fill="#2f2e41"/>
      <ellipse cx="158.1" cy="262.4" rx="23.9" ry="18.7" fill="#2f2e41"/>
      <ellipse cx="164.5" cy="281.5" rx="4.5" ry="8" fill="#a0616a"/>
      <path fill="#f1f1f1" d="M406.2 21.7l511.4 153.5-123.4 411-511.4-153.7z"/>
      <path fill="none" stroke="#3f3d56" strokeMiterlimit="10" strokeWidth="2" d="M611.2 141.9l121.1 49"/>
      <path fill="#3f3d56" d="M614.2 152l1.9-.9-4.2-9 9.2-3.4-.7-2-11.2 4.4 5 10.9zM723.1 196l-.7-2 9.2-3.4-4.2-9 1.8-.8 5.1 10.9-11.2 4.3z"/>
      <circle cx="671.7" cy="166.4" r="11" fill="#3f3d56"/>
      <path fill="none" stroke="#3f3d56" strokeMiterlimit="10" strokeWidth="2" d="M601.9 481.4l111.6-68"/>
      <path fill="#3f3d56" d="M611.8 484.9l.5-2-9.7-2 2.7-9.4-2-.6-3.2 11.6 11.7 2.4zM712.1 423.8l-1.9-.5 2.7-9.5-9.7-2 .4-2 11.8 2.5-3.3 11.5z"/>
      <circle cx="657.7" cy="447.4" r="11" fill="#3f3d56"/>
      <path fill="none" stroke="#3f3d56" strokeMiterlimit="10" strokeWidth="2" d="M461.7 266l.1 130.7"/>
      <path fill="#3f3d56" d="M453.5 272.7l1.5 1.3 6.7-7.2 6.7 7.2 1.5-1.3-8.2-8.8-8.2 8.8zM453.6 390l1.4-1.3 6.8 7.2 6.7-7.2 1.4 1.4-8.1 8.8-8.2-8.8z"/>
      <circle cx="461.7" cy="331.4" r="11" fill="#3f3d56"/>
      <path fill="#3f3d56" d="M369 0h182v226H369z"/>
      <path d="M448.6 84.1h-41.3a144.5 144.5 0 010-49.2h41.3a88.6 88.6 0 000 49.2z" fill="#ff6584"/>
      <path d="M471.4 109.1h41.3a144.5 144.5 0 000-49.2h-41.3a88.6 88.6 0 010 49.2zM432.4 191.1h41.3a144.5 144.5 0 000-49.2h-41.3a88.6 88.6 0 010 49.2z" fill="#3b85c2"/>
      <path fill="#3f3d56" d="M748 215h182v226H748z"/>
      <path fill="#3b85c2" d="M774 263h130v6H774zM774 284h130v6H774zM774 305h130v6H774zM774 326h130v6H774zM774 347h130v6H774zM805 369h68v24h-68z"/>
      <path fill="#3f3d56" d="M385 447h182v226H385z"/>
      <path fill="#3b85c2" d="M411.3 572.8h130v6h-130zM411.3 593.8h130v6h-130zM411.3 614.8h130v6h-130zM411.3 635.8h130v6h-130z"/>
      <path d="M451.1 527.4h-41.3a144.5 144.5 0 010-49.2h41.3a88.6 88.6 0 000 49.2zM468.3 482.8h76v5h-76zM468.3 500.8h76v5h-76zM468.3 518.8h76v5h-76zM468.3 536.8h76v5h-76z" fill="#3b85c2" opacity=".3"/>
    </svg>
)

export default ExecutiveCoaching
