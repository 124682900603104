import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from '../util/colors'

const Container = styled.section`
  align-items: center;
  background-color: ${p => p.color};
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: max(3rem, 5vw);
  position: relative;

  @media (max-width: 650px) {
    flex-direction: column;
    padding: 8vw;
  }
`

const Section = forwardRef(({ children, ...rest }, ref) => (
  <Container ref={ref} {...rest}>
    {children}
  </Container>
))

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  color: PropTypes.string,
}
Section.defaultProps = {
  color: colors.white,
}

export default Section
